import CalcFields from './components/CalcFields';
import logo from './logo.svg';
import React, {Component} from 'react';
import './App.css';

class App extends Component{
  constructor(){
    super();
  }
  render(){
    return (
      <div>
        <div className='calc-body'>
          <h1>Time For Money</h1>
          <CalcFields />
        </div>
      </div>
    )
  }
}

export default App;
