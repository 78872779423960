import React, { useEffect, useState  } from "react";

function CalcFields() {
    const [hourlyRate, setHourly] = useState(0);
    const [annual, setAnnual] = useState(0);
    const [taxRate, setTax] = useState(-1);
    const [cost, setCost] = useState(0);
    const [time, setTime] = useState(0);
    const [hrsWeek, setHrsWeek] = useState(40);
    const [weeksToWork, setWeeks] = useState(0);

    useEffect(() => {
        document.title = 'Time For Money';
        calcCost();
    },[cost, hourlyRate, taxRate]);

    function hourlyRateChange(e) {
        var newVal = e.target.value;
        if(newVal.length>6) return;
        setHourly(e.target.value);
        calcAnnual(newVal, hrsWeek);
        calcCost();
    }

    function annualChange(e) {
        var newVal = e.target.value;
        if(newVal.length>7) return;
        setAnnual(e.target.value);
        if (isNaN(hrsWeek)){
            calcHourly(newVal, 40.00);
        }else{
            calcHourly(newVal, hrsWeek);
        }
        calcCost();
    }

    function taxRateChange(e){
        var newVal = e.target.value;
        if(newVal.length>3) return;
        if(isNaN(newVal)) return;
        if(newVal>999){
            alert("Invalid tax rate. Enter a number between 1 and 99");
            return;
        }
        setTax(newVal);
        calcCost();
    }
    function costChange(e){
        setCost(e.target.value);
        calcCost();
    }
    function hrsWeekChange(e){
        var newVal = e.target.value;
        if(newVal.length>3) {
            return;
        }
        var hrs = e.target.value;
        if (isNaN(hrs)){
            setHrsWeek(-1);
            return;
        }
        if(hrs>168.00){
            alert("Invalid number of hours per week");
            return;
        }
        setHrsWeek(hrs);
        calcHourly(annual, hrs);
        calcExtras(time, hrs);
    }
    function calcAnnual(h, w){
        setAnnual((h*w*52.00).toFixed(0));
    }
    function calcHourly(a, w){
        setHourly((a/w/52.00).toFixed(2));
    }
    function calcCost() {
        if(hourlyRate>0){
            debugger;
            var rate = taxRate > 0 ? taxRate : 1.0;
            var actual = 1.00 - (rate > 1 ? (rate/100.00) : rate);
            if (actual == 0) {actual = 1.00}
            var val = cost/(hourlyRate * actual);
            if (isNaN(val)){
                setTime(-1);
                return;
            }
            setTime(val.toFixed(2));
            calcExtras(val, hrsWeek);
        }
    }
    function calcExtras(t, h){
        if(t > 0 && h > 0) {
            var nbrWeeks = (t/h);
            if (!isNaN(nbrWeeks)){
                setWeeks(nbrWeeks.toFixed(2));
            }
        }
    }

    return (
        <>
        <div className="result">
            <text>You know time is money, but have you ever really thought about what that means? Time For Money lets you see exactly how much time you need to work to earn enough money to afford what you want to buy. Simply provide a few pieces of information and instantly see if your next purchase is worth your time.</text>
        </div>
        <div>&nbsp;</div>
        <div>
            <span className="cap"><text>Annual Income</text></span><span className="txt"><input maxLength="6" type="number" value={(annual > 0 ? annual : '')} onChange={annualChange} /></span>
        </div>
        <div>&nbsp;</div>
        Or
        <div>&nbsp;</div>
        <div>
            <span className="cap"><text>Hourly Income</text></span><span className="txt"><input maxLength="6" type="number" value={(hourlyRate > 0 ? hourlyRate : '')} onChange={hourlyRateChange} /></span>
        </div>
        <div>&nbsp;</div>
            <hr></hr>
        <div>
            <span className="cap"><text>Hours Per Week</text></span><span className="txt"><input maxLength="3" type="number" value={hrsWeek} onChange={hrsWeekChange} /></span>
        </div>
        <div>&nbsp;</div>
        <div>
        <hr></hr>
            <span className="cap"><text>Tax Rate (% or decimal value)</text></span><span className="txt"><input maxLength="6" type="number" value={(taxRate > 0 ? taxRate : '')} onChange={taxRateChange} /></span>
        </div>
        <div>&nbsp;</div>
        <div>
            <hr></hr>
            <span className="cap"><text>Item Cost</text></span><span className="txt"><input maxLength="8" type="number" value={(cost > 0 ? cost : '')} onChange={costChange} /></span>
        </div>
        <div>&nbsp;</div>
<div>
    <hr></hr>
    <text>Time Spent</text>
</div>
<div>
    <text><b>{time > -1 ? time: 0}</b> hours</text>
</div>

<div>
    <text><b>{weeksToWork}</b> weeks</text>
</div>
<div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <hr></hr>
    <text>Fun fact: Decimeters are the most underrated unit of measurement</text>
</div>
<div><hr></hr>
<text>This site does not collect your financial data</text>
</div>

        </>
    );
}
export default CalcFields;